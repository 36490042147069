import { Checkbox, Error, TextArea } from 'components';
import { analytics } from 'globals/utils/analytics';
import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { ChatWithUs } from '../../../components/fcp-components/ChatWithUs';
import { countryConfigQueryKey } from '../../../hooks/api/useGetCountryConfig';
import { withQueryClient } from '../../../utils/withQueryClient';
import { CONST, config } from '../../data-config';
import { disableOtherTabs } from '../AppTabs/AppTabs-helpers';
import { fillDeliveryOptionsData, servicePtResAddress } from '../EnterPickupLocation/SetTemplate-helpers';
import { getAdditionalServicesForOneSelectedProduct } from '../ServiceDetails/ServiceDetails';
import { TermsOfDelivery } from '../TermsOfDelivery/TermsOfDelivery';
import { onSaveForFutureClick, saveShipmentCall } from './../../helpers/savedShipments';
import AdditionalServices from './AdditionalServices/AdditionalServices';
import {
  checkSubmit,
  getProductBasedPrice,
  getQuoteForPriceWithAd,
  getTimeTable,
  isValidBankPlusGiroNumber,
  mapPriceQuoteToAdditionalService,
  servicePointCallAndFormDesiredPickupDate,
  updateServicePointToReceiverAddress,
} from './DeliveryOptions-helpers';
import SelectServicePoint from './SelectServicePoint/SelectServicePoint';
import SelectServices from './SelectServices/SelectServices';
import ServicePointLocatorDialog from './ServicePointLocatorDialog/ServicePointLocatorDialog';
import { Box } from '@mui/material';
import { INTERNATIONAL_SHIPMENT_RANGE, PRODUCTS_RANGE } from '../../../globals/constants';
import { toast } from 'react-toastify';
import { Alert } from '@mui/material';
import i18n from 'i18next';
import { checkFixedDateSelection } from '../../../globals/helpers/deliveryOptions-helpers';
import {
  pickupDatesSelector,
  productPickupDatesSelector,
  selectedPickupDateSelector,
  setPickupDates,
  setSelectedPickupDate,
} from '../../../store/portalOrderSlice';
import PickupDate from '../PickupDate/PickupDate';
import { connect } from 'react-redux';

class DeliveryOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDialogVisible: false,
      additionalServicesGeneralError: false,
      nextBtnLoader: false,
      deliveryOptionsError: false,
      saveForFutureError: false,
      saveShipmentsError: false,
      saveForFutureLoader: false,
      lastNonResidentialAddress: null,
      isInitialLoading: true,
      countryConfig: undefined,
      missingDGItemsForDGVAS: false,
    };

    this.loaderRef = React.createRef();
  }

  componentDidMount = async () => {
    const countryConfig = this.props.queryClient.getQueryData([countryConfigQueryKey], {
      type: 'active',
      exact: false,
    });
    this.setState({ countryConfig });

    if (process.env.NODE_ENV !== 'test') window.scrollTo(0, 0);

    // we need the getTimeTable data before any vas call because it extends matchedProducts data (eg. with priorityServices property)
    if (this.props.context.state?.pickupDate?.value) {
      await getTimeTable(
        this.props.context.state.pickupDate.value,
        this.props.context,
        false,
        true,
        undefined,
        undefined,
      );
    }

    // make call only when tab 4 or tab 5 are not touched
    if (!this.props.context.state.tab4OrTab5Touched) {
      const isSwedenCountry = localStorage.getItem('country')?.toUpperCase() === 'SE';

      this.loaderRef.current.classList.add('is-loading');
      const servicePtId =
        this.props.context.state.servicePointId ||
        (this.props.context.state.templateData && this.props.context.state.templateData.servicePoint);

      let stateToSet = await servicePointCallAndFormDesiredPickupDate(this.props.context, servicePtId);
      if (isSwedenCountry) {
        if (this.props.context.state.desiredPickupDates?.length) {
          stateToSet.pickupMinDate = this.props.context.state.desiredPickupDates[0];
        } else {
          stateToSet.pickupMinDate = this.props.context.state.pickupDate.value;
        }
      }
      if (stateToSet?.selectedServicePoint && stateToSet?.servicePoints) {
        this.props.context.updateState({
          servicePoints: stateToSet.servicePoints,
          selectedServicePoint: stateToSet.selectedServicePoint,
        });
      }

      // template data - fill
      if (this.props.context.state.templateData) {
        stateToSet = Object.assign(await fillDeliveryOptionsData(this.props.context, countryConfig), stateToSet);
        Object.assign(stateToSet, servicePtResAddress(stateToSet));

        // pre selected service pt
        if (servicePtId) {
          const quotePriceObj = await getProductBasedPrice(stateToSet);

          if (!quotePriceObj.quotePriceError) {
            const newAdditionalServices = JSON.parse(JSON.stringify(stateToSet.additionalServices));

            mapPriceQuoteToAdditionalService(quotePriceObj, newAdditionalServices);

            stateToSet.additionalServices = newAdditionalServices;
          }

          Object.assign(stateToSet, { ...quotePriceObj });
        }
      } else if (this.props.context.state.selectedProduct.code) {
        // make vas call - if product is previously selected - may change of country expected
        Object.assign(
          stateToSet,
          await getAdditionalServicesForOneSelectedProduct(
            this.props.context.state,
            true,
            true,
            this.props.context.state.selectedProduct,
            countryConfig,
          ),
        );
      }

      this.loaderRef.current.classList.remove('is-loading');
      this.props.context.updateState(stateToSet);
    }

    // Preselected product was not matched
    if (
      this.props.context.state.productRange &&
      this.props.context.state.productRange !== PRODUCTS_RANGE.ACCOUNT &&
      this.props.context.state.productRange !== PRODUCTS_RANGE.COUNTRY &&
      !this.props.context.state.matchedProducts.some(product => product.code === this.props.context.state.productRange)
    ) {
      toast(
        <Alert severity="error" variant="standard">
          {i18n.t('general|errors|serviceNotAvailable', {
            service: i18n.t(`products|${this.props.context.state.productRange}|name`),
          })}
        </Alert>,
      );
    }
    this.setState({ isInitialLoading: false });
  };

  componentDidUpdate(prevProps) {
    // TODO: fix me - call LTC when is NOT selected product only - should be from BE
    if (
      !this.props.context.state.selectedProduct?.code &&
      prevProps.selectedPickupDate !== this.props.selectedPickupDate
    ) {
      getTimeTable(
        this.props.selectedPickupDate,
        this.props.context,
        this.loaderRef.current,
        true,
        undefined,
        undefined,
      );
    }
  }

  handleDialog = dialogState => {
    this.setState({
      isDialogVisible: dialogState,
    });
    this.props.context.updateState({
      servicePointsError: false,
    });
  };

  handleSaveForFutureClick = async t => {
    this.setState({ saveForFutureLoader: true }, async () => {
      const result = await onSaveForFutureClick(this.props.context, t);

      if (result.error) {
        this.setState({ saveForFutureError: true, saveForFutureLoader: false });
      }
    });
  };

  enableOtherTabsOnError = localStateToSet => {
    disableOtherTabs(this.props, false, [3], config.tabs.tab3);
    localStateToSet.nextBtnLoader = false;

    this.setState(localStateToSet);
  };

  onGoClick = async () => {
    disableOtherTabs(this.props, true, [3], config.tabs.tab3);

    const dgVASSelected = this.props.context.state.additionalServices.some(
      vas => vas.value && vas.code === 'dangerousGoods',
    );
    const noDGItemsProvided = !this.props.context.state.shipmentDetailsRows.some(shipmentRow =>
      shipmentRow.dangerousGoodGroup.some(dgItem => dgItem.dgmId?.value),
    );
    if (
      noDGItemsProvided &&
      dgVASSelected &&
      (this.state.countryConfig?.domesticDangerousGoodsEnabled ||
        this.props.context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE)
    ) {
      this.setState({ missingDGItemsForDGVAS: true });
      return;
    }

    if (this.props.context.state.isb2cProduct) {
      if (!this.props.context.state.selectedServicePoint.cityName) {
        this.setState({ deliveryOptionsError: true });
        return;
      }
    }

    this.setState({ nextBtnLoader: true, deliveryOptionsError: false }, async () => {
      // Validation on submit
      const checkPerformedSuccessfully = !(await checkSubmit(this.props.context));
      const localStateToSet = {};

      //Validate Bank Giro or Plus Giro
      const isValidBankPlusGiro = await isValidBankPlusGiroNumber(this.props.context);

      if (checkPerformedSuccessfully && isValidBankPlusGiro) {
        const savedShipmentResult = await saveShipmentCall(this.props.context);

        if (!savedShipmentResult.error) {
          analytics('step 3', CONST.ANALYTICS);
          this.props.nextTab(this.props.context.state.returnProdStatus, config.tabs.tab4);
        } else {
          // set save shipment error
          localStateToSet.saveShipmentsError = savedShipmentResult.error || false;
          this.enableOtherTabsOnError(localStateToSet);
        }
      } else {
        this.enableOtherTabsOnError(localStateToSet);
      }
    });
  };

  render() {
    const { state } = this.props.context;

    const error = !state.selectedServicePoint.cityName;
    const disableBtn = this.state.nextBtnLoader || this.state.saveForFutureLoader;

    const disableNextButton =
      this.state.nextBtnLoader ||
      (state.isb2cProduct && error) ||
      state.additionalServicesError ||
      !state.pickupDate.value;

    const servicePoint = state.servicePointUseResidentialAddress.value
      ? state.selectedServicePoint
      : state.selectedServicePoint.cityName
      ? state.selectedServicePoint
      : state.servicePoints.length > 0
      ? state.servicePoints[0]
      : {};

    if (this.state.isInitialLoading) {
      return (
        <div
          id="fcp-delivery-options-loader"
          ref={this.loaderRef}
          className={'frc__generic--loader-overlay  is-loading'}
        ></div>
      );
    }

    return (
      <div className="frc__generic-section--wrapper">
        <Translation>
          {t => (
            <>
              <PickupDate
                isReturnProduct={state.pickupAddressResidential.value}
                isPortalOrder
                onGetTimeTable={value =>
                  getTimeTable(value, this.props.context, this.loaderRef.current, true, undefined, undefined)
                }
                disabled={checkFixedDateSelection(this.props.context, false)}
                contextUpdateField={this.props.context.updateField}
                contextUpdateState={this.props.context.updateState}
                contextPickupDateError={this.props.context.state.pickupDate.error}
                contextSelectedProduct={this.props.context.state.selectedProduct}
                contextMatchedProducts={this.props.context.state.matchedProducts}
                shipmentRange={this.props.context.state.shipmentRange?.value}
                payingAccountNumber={this.props.context.state.payingAccountNumber?.value}
                pickupMinDate={this.props.context.state.pickupMinDate}
                pickupDatesSelector={pickupDatesSelector}
                productPickupDatesSelector={productPickupDatesSelector}
                selectedPickupDateSelector={selectedPickupDateSelector}
                setPickupDates={setPickupDates}
                setSelectedPickupDate={setSelectedPickupDate}
                additionalServices={this.props.context.state.additionalServices}
              />
              <SelectServices context={this.props.context} />
              {this.state.isDialogVisible ? (
                <ServicePointLocatorDialog context={this.props.context} closeDialog={() => this.handleDialog(false)} />
              ) : (
                ''
              )}
              {state.isb2cProduct ? (
                <>
                  <SelectServicePoint
                    isServicePointTitle={state.servicePointUseResidentialAddress.isProductServicePointDelivery}
                    servicePoint={servicePoint}
                    state={state}
                  />
                  {state.initialServicePointStatus && !state.servicePointsError ? (
                    <button
                      id="changeServicePointLocationBtn"
                      className="frc__red-text-button"
                      onClick={() => this.handleDialog(true)}
                    >
                      {t(`general|Change service point location`)}
                    </button>
                  ) : (
                    ''
                  )}
                  {!state.servicePointUseResidentialAddress.isProductServicePointDelivery && (
                    <Checkbox
                      inlineTooltip={t('general|Use residential address tooltip')}
                      label={t('general|Use residential address')}
                      name={'servicePointUseResidentialAddress'}
                      context={this.props.context}
                      checked={state.servicePointUseResidentialAddress.value}
                      isRequired={false}
                      config={config}
                      CONST={CONST}
                      disabled={state.servicePointUseResidentialAddress.disabled}
                      updateOnParent={params => {
                        if (params.value) {
                          this.setState({ lastNonResidentialAddress: servicePoint });
                        } else {
                          this.setState({ lastNonResidentialAddress: null });
                        }
                        updateServicePointToReceiverAddress(params, this.props, this.state.lastNonResidentialAddress);
                      }}
                    />
                  )}
                </>
              ) : (
                ''
              )}
              <TermsOfDelivery context={this.props.context} loaderRef={this.loaderRef} />
              {this.props.context.state.selectedProduct && this.props.context.state.selectedProduct.code && (
                <AdditionalServices
                  loaderRef={this.loaderRef}
                  getQuoteForPriceWithAd={additionalServiceRow => {
                    // when it is checked "DG VAS" check if it is unchecked action => remove useless error message
                    if (
                      additionalServiceRow.code === 'dangerousGoods' &&
                      additionalServiceRow.value === false &&
                      this.state.missingDGItemsForDGVAS === true
                    ) {
                      this.setState({ missingDGItemsForDGVAS: false });
                    }
                    getQuoteForPriceWithAd(this.props.context, additionalServiceRow, this.state.countryConfig);
                  }}
                  context={this.props.context}
                />
              )}
              {state.returnProdStatus && (
                <div className="l-grid--w-100pc-s l-grid--w-48pc-m margin-bottom-1">
                  <h4 className="frc__red-lined-title">{t(`general|Delivery Instructions`)}</h4>

                  <TextArea
                    className="frc__textarea--wrapper frc__generic--field-wrapper-no-top"
                    textAreaClassName="frc__textarea--tiny"
                    regEx={config.regEx.everything}
                    label={t(`general|Add Delivery Instructions (Optional)`)}
                    smallLabel={t(`general|Add instructions for driver <strong>delivering</strong> this shipment`)}
                    name="deliveryInstructions"
                    isRequired={false}
                    value={state.deliveryInstructions.value}
                    lengthCheck={[RegExp(`[\\r\\n]{0,${config.maxInstructionsLength}}$`)]}
                    cutTextLimit={config.maxInstructionsLength}
                    context={this.props.context}
                  />
                  {state.deliveryInstructions.error && (
                    <Error
                      name="deliveryInstructions"
                      className="frc__input--error"
                      message={t(`general|errors|Driver Instructions Error`, {
                        chars: config.maxInstructionsLength,
                      })}
                    />
                  )}
                </div>
              )}
              <div className="frc__generic-row--wrapper">
                <div className="l-grid l-grid--w-100pc-s frc__spacebetween-firstleft">
                  <button
                    id="backToShipmentDetailsBtn"
                    className={`base-button base-button--white l-grid--w-25pc-w ${disableBtn ? 'disabled' : ''}`}
                    onClick={disableBtn ? null : () => this.props.previousTab('tab2', 2)}
                    disabled={disableBtn}
                  >
                    <span>{t(`general|Back to Shipment Details`)}</span>
                  </button>
                  <button
                    id="saveForFutureBtn"
                    className={
                      'base-button base-button--white l-grid--w-25pc-w frc__saveforfuturebtn ' +
                      (disableBtn ? ' disabled' : '') +
                      (this.state.saveForFutureLoader ? ' is-loading--right ' : '')
                    }
                    onClick={disableBtn ? null : () => this.handleSaveForFutureClick(t)}
                    disabled={disableBtn}
                  >
                    <span>{t(`portalOrder|Save for future`)}</span>
                  </button>
                  <button
                    id="goToSchedulePickupBtn"
                    data-testid="next-button"
                    className={
                      'base-button base-button--wide l-grid--w-25pc-w ' +
                      (disableNextButton ? 'disabled' : '') +
                      (this.state.nextBtnLoader ? ' is-loading--right' : '')
                    }
                    disabled={disableNextButton}
                    onClick={disableNextButton ? null : () => this.onGoClick()}
                  >
                    <span>{t('general|labels|buttons|Next Step')}</span>
                  </button>
                </div>

                <div className="l-grid--right-s">
                  {!state.dangerousGoodsLinkedIfNeeded ? (
                    <Error
                      name="vasDifferentErrors"
                      message={t(`portalOrder|Dangerous goods VAS needs to be selected`)}
                    />
                  ) : (
                    ''
                  )}
                </div>

                <div className="l-grid--right-s">
                  {this.state.missingDGItemsForDGVAS ? (
                    <Box display="flex" justifyContent="flex-end">
                      <Box maxWidth="700px">
                        <Error
                          name="vasDifferentErrors"
                          message={t('general|dangerousGoods|dangerousGoodsVASWithNoDGItems')}
                        />
                      </Box>
                    </Box>
                  ) : null}
                  {state.additionalServicesError ||
                  this.state.saveShipmentsError ||
                  this.state.saveForFutureError ||
                  (state.isb2cProduct && error) ||
                  state.additionalServicesGeneralError ||
                  !state.pickupDate.value ? (
                    <Error
                      name="vasDifferentErrors"
                      message={
                        state.isb2cProduct && error
                          ? t(`general|Cannot continue without delivery options`)
                          : state.additionalServicesGeneralError || !state.pickupDate.value
                          ? t('general|errors|Please fill properly all necessary fields')
                          : this.state.saveShipmentsError || this.state.saveForFutureError
                          ? t(`portalOrder|Shipment could not be saved`)
                          : t('general|errors|General error') +
                            '. <br> ' +
                            t('general|Try again or call technical support.') +
                            ' ' +
                            t('general|or') +
                            ' ' +
                            <ChatWithUs /> +
                            ' <br> ' +
                            t('general|ID') +
                            ': ' +
                            state.transactionId
                      }
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div
                id="fcp-delivery-options-loader"
                ref={this.loaderRef}
                className={
                  'frc__generic--loader-overlay' +
                  (state.loaders.additionalServices.value || state.hereLoading ? ' is-loading ' : '')
                }
              ></div>
            </>
          )}
        </Translation>
      </div>
    );
  }
}

const QueryClientDeliveryOptions = withQueryClient(DeliveryOptions);

const mapStateToProps = state => ({
  selectedPickupDate: state.portalOrder.selectedPickupDate,
});

export default connect(mapStateToProps, null)(QueryClientDeliveryOptions);
